
.content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 75px;
    
    @media screen and (max-width: 890px) {
       gap: 0;
       grid-template-columns: 1fr;
    }

    .motif-row {    
        margin-left: 74px;
        display: inline-block;
        width: calc(100% - 74px);
        margin-right: 10px;
    
        &__image {
            width: calc(33% - 5px);
            height: 100%;
            display: inline-block;
            cursor: pointer;
            padding: 5px;
            margin-bottom: 10px;
            margin-right: 3px;
    
            &.active,
            &:hover {
                background: $swiss-color-grey-m4;
            }
    
            img {
                width: 100%;
            }
        }
    
        span {
            font-size: 15px;
            color: #000;
        }
    }

    .form {
        form {
            display: none;
            visibility: hidden;
            opacity: 0;
        }

        &__row {
            display: block;
            height: auto;
            position: relative;

            select[name="motive"] {
                display: none;
            }

            &.divider {
                height: 30px;
            }

            &[data-active="1"] {
                .form__shadow {
                    display: none;
                }
            }

            &[data-step="3"] {
                cursor: pointer;

                .form__step {
                    border-color: $swiss-color-grey-m3;

                    span {
                        top: calc(50% + 2px);
                    }

                    svg {
                        width: 25px;
                        height: 25px;

                        * {
                            fill: #000;
                        }
                    }
                }

                &[data-active="1"] {
                    .form__step {
                        border-color: $swiss-color-red;

                        svg {
                            width: 25px;
                            height: 25px;

                            * {
                                fill: $swiss-color-red;
                            }
                        }
                    }
                    .form__label {
                        padding: 20px 46px 20px 44px;
                        background: $swiss-color-red;
                    }
                }
                .form__label {
                    padding: 20px 46px 20px 44px;
                    background: $swiss-color-grey-m3;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 600;
                    margin-left: 16px;
                    border-radius: 2px;
                }
            }
        }

        &__shadow {
            display: block;
            background: rgba(255, 255, 255, 0.7);
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 99;
        }

        &__step {
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            position: relative;
            background: #fff;
            border: 2px solid $swiss-color-grey-m5;
            z-index: 1;
            cursor: pointer;

            &[data-active="1"] {
                background: $swiss-color-grey-m5;
            }

            span {
                font-weight: 600;
                font-size: 20px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
            }
        }

        &__label {
            display: inline-block;
            text-align: left;
            padding: 0 20px;
            vertical-align: 18px;
        }

        &__input {
            display: inline-block;
            text-align: right;
            vertical-align: middle;
            text-align: right;
            float: right;
            margin-top: 12px;
            width: 200px;

            select,
            input {
                width: 100%;
                font-weight: normal;
            }

            input {
                outline: none;
                padding: 5px 10px 5px 0;
                border: 0;
                border-bottom: 1px solid #000;
            }
        }

        &__divider {
            position: absolute;
            left: 25px;
            top: -6px;
            height: 40px;
            width: 1px;
            background: transparent;
            z-index: 0;

            &.xtra-lng {    
                top: -653px;
                height: 683px;
            }

            &[data-active="1"] {
                background: $swiss-color-grey-m5;
            }
        }
    }

    .preview {
        position: relative;
        padding: 0 15px;
        margin-top: 10px;

        &__pdf {   
            min-height: 600px;
            display: block;
            position: relative;

            &.with-border {
                border: 1px solid $swiss-color-grey-m3;
            }
        }

        &__file  {
            display: none;
            position: absolute;

            img {
                max-width: 100%;
                border: 1px solid $swiss-color-grey;
            }
        }

        &__loading {
            position: absolute;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.4);
            z-index: 999;
            display: none;    
            left: 0;

            &.load {
                display: block;
            }
        }

        &__no-preview {
            position: absolute;
            top: 50%;
            line-height: 1.3;
            transform: translateY(-50%);
            font-size: 14px;
            color: $swiss-color-grey-m3;
            text-align: center;
            padding: 70px;
            display: block;
        }
    }
}